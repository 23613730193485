<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="LhdnInvoices"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-sheet class="dense-inputs">
                        <v-row no-gutters class="d-flex flex-row align-center pt-3 px-0 flex-md-wrap col-12">
                            <div class="d-flex flex-column">
                                <div class="d-flex flex-row">
                                    <v-text-field
                                        :placeholder="$t('message.contractPo') + '/' + $t('message.invoiceDebitNote')"
                                        :value="searchTerm"
                                        autocomplete="new-password"
                                        class="filter-width-200 force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        prepend-inner-icon="search"
                                        solo
                                        @change="searchTerm = $event"
                                    />
                                    <v-btn
                                        :loading="loading.clear"
                                        class="ml-2 px-2"
                                        @click="searchAgentCommissions()"
                                    >{{ $t('message.search') }}</v-btn>
                                </div>
                            </div>
                            <div class="d-flex flex-row align-center">
                                <v-autocomplete
                                    :items="agents"
                                    :loading="loading.filter.agents"
                                    :placeholder="$t('message.agent')"
                                    class="filter-width-175 ml-2"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Agent.title"
                                    item-value="Agent.id"
                                    solo
                                    v-model="filterValues.agent"
                                    @change="getAgentCommissions()"
                                />
                                <v-autocomplete
                                    :items="suppliers"
                                    :loading="loading.filter.suppliers"
                                    :placeholder="$t('message.supplier')"
                                    class="filter-width-175 ml-2"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Supplier.title"
                                    item-value="Supplier.id"
                                    solo
                                    v-model="filterValues.supplier"
                                    @change="getAgentCommissions()"
                                />
                                <v-select
                                    :items="years"
                                    :placeholder="$t('message.year')"
                                    class="filter-width-120 ml-2"
                                    dense
                                    hide-details="auto"
                                    item-value="value"
                                    item-text="text"
                                    solo
                                    v-model="filterValues.year"
                                    @change="getAgentCommissions()"
                                />
                                <v-select
                                    :items="months"
                                    :placeholder="$t('message.month')"
                                    :disabled="filterValues.year == null"
                                    class="filter-width-120 ml-2"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="Month.id"
                                    item-text="Month.text"
                                    solo
                                    v-model="filterValues.month"
                                    @change="getAgentCommissions()"
                                />
                                <v-btn
                                    :loading="loading.clear"
                                    class="ml-2 px-2"
                                    @click="clearFilters"
                                >{{ $t('message.clear') }}</v-btn>
                            </div>
                            <v-spacer/>
                            <ExportTableJson
                                :export-conditions="exportConditions"
                                :export-data="commissions"
                                :export-fields="headers"
                                :export-source="'agentCommissions'"
                                class="ml-3"
                            />
                        </v-row>
                    </v-sheet>
                    <v-overlay
                        :value="loading.items"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                />
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                            showFirstLastPage: true
                        }"
                        :headers="headers"
                        :items="commissions"
                        :options="tableOptions"
                        :server-items-length="totalCommissions"
                        :search="searchTerm"
                        calculate-widths
                        class="appic-table-light specification-table col-12 px-0"
                        dense
                        id="agentCommissionsTable"
                        item-key="Commission.id"
                        @update:options="updateDataTable"
                    >
                        <template v-slot:item.Commission.id="{ item }">
                            <div class="text-center">
                                <v-menu :value="actionMenuIsOpen">
                                    <template v-slot:activator="{ on: menu }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                    <v-icon>more_vert</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('message.moreActions') }}</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list dense>
                                        <v-list-item class="font-sm" @click="updateInvoice(item.Commission.invoice_id)" v-if="['DS','SS'].includes(item.Commission.sales_type) && $can('update','Invoice') && item.Commission.invoice_id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateInvoice') }}</v-list-item>
                                        <v-list-item class="font-sm" @click="updateDebitNote(item.Commission.debitnote_id)" v-if="['CS'].includes(item.Commission.sales_type) && $can('update','DebitNote') && item.Commission.debitnote_id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDebitNote') }}</v-list-item>
                                        <v-list-item class="font-sm" @click="updatePurchaseOrder(item.Commission.purchaseorder_id)" v-if="item.Commission.sales_type === 'PO' && $can('update','Po')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePurchaseOrder') }}</v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </template>
                        <template v-slot:item.Commission.invoice_number="{ item }">
                            <div class="text-left" v-if="item.Commission.sales_type !== 'PO' && item.Commission.invoice_number != null">{{ item.Commission.invoice_number }}</div>
                            <div class="text-left red--text text-darken-1" v-if="item.Commission.sales_type !== 'PO' && item.Commission.invoice_number == null">{{ $t('message.pending') }}</div>
                            <div class="text-left" v-if="item.Commission.sales_type === 'PO'">{{ $t('message.na') }}</div>
                        </template>
                        <template v-slot:item.Commission.date="{ item }">
                            {{ item.Commission.date !== '0000-00-00' ? formatDate(item.Commission.date) : '' }}
                        </template>
                        <template v-slot:item.Commission.document_amount="{ item }">
                            <div class="text-right" v-if="item.Commission.document_amount != null">{{ formatThisNumber(item.Commission.document_amount, '0,0.00') }}</div>
                        </template>
                        <template v-slot:item.Commission.quantity="{ item }">
                            <div class="text-right" v-if="item.Commission.quantity != null">{{ formatThisNumber(item.Commission.quantity, '0,0.000') }}</div>
                        </template>
                        <template v-slot:item.Commission.paid_date="{ item }">
                            <div v-if="item.Commission.paid_date != null">{{ item.Commission.paid_date !== '0000-00-00' ? formatDate(item.Commission.paid_date) : '' }}</div>
                            <div class="red--text text-darken-1" v-else>{{ $t('message.pending') }}</div>
                        </template>
                        <template v-slot:item.Commission.commission_amount="{ item }">
                            <div class="text-right" v-if="item.Commission.commission_amount != null">{{ formatThisNumber(item.Commission.commission_amount, '0,0.00') }}</div>
                            <div class="text-right red--text text-darken-1" v-else>{{ $t('message.pending') }}</div>
                        </template>
                    </v-data-table>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { api } from "Api";
import { formatDate, isObject, numberFormat } from "Helpers/helpers";

const ExportTableJson = () => import("Components/Appic/ExportTableJson");

export default {
    name: "AgentCommissions",
    components: {ExportTableJson},
    data() {
        return {
            actionMenuIsOpen: false,
            agents: [],
            commissions: [],
            exportConditions: {},
            filterValues: {
                agent: 'all',
                supplier: 'all',
                month: null,
                status: null,
                year: 2024
            },
            loader: false,
            loading: {
                clear: false,
                filterResults: false,
                filter: {
                    agents: false,
                    suppliers: false
                },
                search: false,
                items: false
            },
            searchTerm: null,
            suppliers: [],
            tableOptions: {
                page: 1,
                itemsPerPage: 15,
                sortBy: ['Commission.date'],
                sortDesc: [true]
            },
            totalCommissions: 0,
            years: []
        }
    },
    computed: {
        ...mapGetters([
            'months'
        ]),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        defaultItemsPerPage() {
            if(window.screen.height >= 800) return 15
            return 10
        },
        headers() {
            return [
                {
                    index: 1,
                    text: this.$t('message.actions'),
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    value: 'Commission.id',
                    searchable: false,
                    sortable: false
                },
                {
                    index: 2,
                    text: this.$t('message.invoiceDebitNoteDate'),
                    value: 'Commission.date',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    index: 3,
                    text: this.$t('message.contractPo'),
                    value: 'Commission.contract_number',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    index: 4,
                    text: this.$t('message.invoiceDebitNote'),
                    value: 'Commission.invoice_number',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    index: 5,
                    text: this.$t('message.agent'),
                    value: 'Commission.agent',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    index: 6,
                    text: this.$t('message.supplier'),
                    value: 'Commission.supplier',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    index: 7,
                    text: this.$t('message.buyer'),
                    value: 'Commission.buyer',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    index: 8,
                    text: this.$t('message.amount'),
                    value: 'Commission.document_amount',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                },
                {
                    index: 9,
                    text: this.$t('message.cur'),
                    value: 'Commission.document_currency',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                },
                {
                    index: 10,
                    text: this.$t('message.quantity'),
                    value: 'Commission.quantity',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-right',
                },
                {
                    index: 11,
                    text: this.$t('message.unit'),
                    value: 'Commission.quantity_unit',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-right',
                },
                {
                    index: 12,
                    text: this.$t('message.invoiceDebitNotePaid'),
                    value: 'Commission.paid_date',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-right',
                },
                {
                    index: 13,
                    text: this.$t('message.commAmount'),
                    value: 'Commission.commission_amount',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-right',
                },
                {
                    index: 14,
                    text: this.$t('message.cur'),
                    value: 'Commission.commission_currency',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                }
            ]
        },
        itemsPerPageOptions() {
            let options = [10,20,50,-1];
            if(window.screen.height >= 800){
                options = [15,30,50,-1];
            }
            return options;
        },
    },
    methods: {
        formatDate,
        clearFilters() {
            this.searchTerm = null
            this.filterValues.supplier = null
            this.filterValues.month = null
            this.filterValues.status = null
            const dt = new Date()
            this.filterValues.year = dt.getFullYear()
            this.tableOptions.page = 1
            this.tableOptions.sortBy[0] = 'Commission.date'
            this.tableOptions.sortDesc[0] = true
            this.loading.clear = false
            this.getAgentCommissions()
        },
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getAgents() {
            this.loading.filter.agents = true
            api
                .get('/suppliers/agent-commissions/agents')
                .then((response) => {
                    if(response.data.status === 'success') {
                        this.agents = response.data.data
                    } else {
                        if(isObject(response.data.message)){
                            //extract validation errors
                            const errors = Object.values(response.data.message)
                            errors.forEach(errorObj => {
                                if (Array.isArray(errorObj)) {
                                    errorObj.forEach(error => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    }
                    this.loading.filter.agents = false
                })
                .catch(() => {
                    this.loading.filter.agents = false
                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        getAgentCommissions() {
            this.loading.items = true

            let conditions = [];
            if (this.filterValues.status != null) {
                conditions.push({
                    field: 'status',
                    value: this.filterValues.status
                })
            }
            if (this.filterValues.agent != null) {
                conditions.push({
                    field: 'agent_id',
                    value: this.filterValues.agent
                })
            }
            if (this.filterValues.supplier != null) {
                conditions.push({
                    field: 'supplier_id',
                    value: this.filterValues.supplier
                })
            }
            if (this.filterValues.month != null) {
                conditions.push({
                    field: 'month',
                    value: this.filterValues.month
                })
            }
            if (this.filterValues.year != null) {
                conditions.push({
                    field: 'year',
                    value: this.filterValues.year
                })
            }

            let order = [];
            this.tableOptions.sortBy.forEach((sort, idx) => {
                order.push(
                    {
                        field: sort,
                        direction: this.tableOptions.sortDesc[idx] ? 'DESC' : 'ASC'
                    }
                )
            })

            api
                .get('/suppliers/agent-commissions', {
                    params: {
                        conditions: conditions,
                        pagination: {
                            skip: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
                            take: this.tableOptions.itemsPerPage
                        },
                        order: order
                    }
                })
                .then((response) => {
                    if(response.data.status === 'success') {
                        this.commissions = response.data.data
                        this.totalCommissions = response.data.totalRows
                    } else {
                        if(isObject(response.data.message)){
                            //extract validation errors
                            const errors = Object.values(response.data.message)
                            errors.forEach(errorObj => {
                                if (Array.isArray(errorObj)) {
                                    errorObj.forEach(error => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    }
                    this.loading.items = false
                })
                .catch((error) => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.items = false
                })
        },
        getSuppliers() {
            this.loading.filter.suppliers = true
            api
                .get('/suppliers/agent-commissions/suppliers')
                .then((response) => {
                    if(response.data.status === 'success') {
                        this.suppliers = response.data.data
                    } else {
                        if(isObject(response.data.message)){
                            //extract validation errors
                            const errors = Object.values(response.data.message)
                            errors.forEach(errorObj => {
                                if (Array.isArray(errorObj)) {
                                    errorObj.forEach(error => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    }
                    this.loading.filter.suppliers = false
                })
                .catch(() => {
                    this.loading.filter.suppliers = false
                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        getYearOptions() {
            let years = []
            let dt = new Date()
            const startYear = dt.getFullYear()
            const endYear = 2024
            for(let i = startYear; i >= endYear; i--){
                years.push({value: i, text: i.toString()})
            }
            this.years = years;
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (230);
        },
        searchAgentCommissions() {
            this.loading.items = true
            this.filterValues.year = null;

            let conditions = [];
            if (this.searchTerm != null) conditions.push({
                field: 'search',
                value: this.searchTerm
            })

            let order = [];
            this.tableOptions.sortBy.forEach((sort, idx) => {
                order.push(
                    {
                        field: sort,
                        direction: this.tableOptions.sortDesc[idx] ? 'DESC' : 'ASC'
                    }
                )
            })

            api
                .get('/suppliers/agent-commissions', {
                    params: {
                        conditions: conditions,
                        pagination: {
                            skip: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
                            take: this.tableOptions.itemsPerPage
                        },
                        order: order
                    }
                })
                .then((response)=>{
                    if(response.data.status === 'success') {
                        this.commissions = response.data.data
                        this.totalCommissions = response.data.totalRows
                    } else {
                        if(isObject(response.data.message)){
                            //extract validation errors
                            const errors = Object.values(response.data.message)
                            errors.forEach(errorObj => {
                                if (Array.isArray(errorObj)) {
                                    errorObj.forEach(error => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    }
                    this.loading.items = false
                })
                .catch((error) => {
                    this.loading.items = false
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        updateDataTable(options) {
            this.tableOptions = options
            this.getAgentCommissions()
        },
        updateDebitNote(val) {
            let tab = window.open('/v1/debitnotes/update/' + val,'_blank')
            tab.name = 'debitNote_' + val
            this.openedTabs.push(tab.name)
        },
        updateInvoice(val) {
            let tab = window.open('/v1/invoices/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updatePurchaseOrder(val) {
            let tab = window.open('/v1/purchaseorders/update/' + val,'_blank')
            tab.name = 'purchaseOrder_' + val
            this.openedTabs.push(tab.name)
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.getYearOptions()
    },
    mounted() {
        this.$title = this.$t('message.titles.agentCommissions')
        this.handleResize()

        this.$nextTick(() => {
            const dt = new Date()
            this.filterValues.year = dt.getFullYear()
            this.tableOptions.itemsPerPage = this.defaultItemsPerPage
            this.tableOptions.sortBy[0] = 'Commission.date'
            this.tableOptions.sortDesc[0] = true

            this.getSuppliers();
            this.getAgents();
        })
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.filter-width-120 {
    width: 120px !important;
    max-width: 120px !important;
}
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.filter-width-200 {
    width: 185px !important;
    max-width: 185px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table tbody td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.height-10px {
    height: 10px;
}
.v-data-table-header th {
    white-space: nowrap;
}
.note {
    font-size: 0.8em;
}
.notes {
    border: 1px solid #3C6932;
}
</style>